import { Injectable } from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { RequestService } from './request.service';
import { endpoints } from '../constants/endpoints';

// import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';


@Injectable({
  providedIn: 'root',
})
export class AppService {
  private toggeleSidebarObservable: Subject<boolean> = new ReplaySubject(1);
  sidebarOpen = this.toggeleSidebarObservable.asObservable();
  screenWidth;

  constructor( private requestService: RequestService,
   ) {
    this.screenWidth = window.innerWidth;

    if (
      this.screenWidth < 1024
    ) {
      this.toggeleSidebarObservable.next(false);
    } else {
      this.toggeleSidebarObservable.next(true);
    }


    // if (this.swUpdate.isEnabled) {
    //   console.log('swUpdate', this.swUpdate);
    //   this.swUpdate.versionUpdates
    //     .pipe(
    //       filter(
    //         (event): event is VersionReadyEvent =>
    //           event.type === 'VERSION_READY'
    //       )
    //     )
    //     .subscribe((event: VersionReadyEvent) => {
    //       console.log('event', event);
    //       if (confirm('A new version is available. Load New Version?')) {
    //         window.location.reload();
    //       }
    //     });
    // }


  }

  toggleSidebar() {
    this.toggeleSidebarObservable
      .pipe(take(1))
      .subscribe((b) => {
        this.toggeleSidebarObservable.next(!b)
      });
  }

  checkPendingInvoice(zohoSubscriptionId, headers) {
    this.requestService
      .send<any>(
        'GET',
        `${endpoints.pendingInvoice}/${zohoSubscriptionId}`,
        {
          headers: headers,
          useBaseUrl: true,
        }
      )
      .then((invoice) => {
        console.log('invoice');
      })
      .catch((error) => {
        console.log('subscription_error');
      });
  }

}
